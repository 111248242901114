import { GoalStep as ApiGoalStep, goalsApi } from '@core/api/goals';

export enum GoalStep {
	IMPORT_1C_SELECT = ApiGoalStep.IMPORT_1C_SELECT,
	IMPORT_1C_DROP = ApiGoalStep.IMPORT_1C_DROP,
	IMPORT_1C_UPLOAD = ApiGoalStep.IMPORT_1C_UPLOAD,
	IMPORT_1C_TEMPLATE = ApiGoalStep.IMPORT_1C_TEMPLATE,
	IMPORT_1C_START = ApiGoalStep.IMPORT_1C_START,
	IMPORT_1C_EXIT = ApiGoalStep.IMPORT_1C_EXIT,
	IMPORT_1C_CANCEL = ApiGoalStep.IMPORT_1C_CANCEL,
	IMPORT_1C_FAIL = ApiGoalStep.IMPORT_1C_FAIL,
	IMPORT_1C_SUCCESS = ApiGoalStep.IMPORT_1C_SUCCESS,

	INVOICE_DOC_LIST_ADD = ApiGoalStep.INVOICE_DOC_LIST_ADD,
	INVOICE_DOC_CARD_ADD = ApiGoalStep.INVOICE_DOC_CARD_ADD,
	INVOICE_DOC_FORM_PREVIEW = ApiGoalStep.INVOICE_DOC_FORM_PREVIEW,
	INVOICE_DOC_FORM_EXIT = ApiGoalStep.INVOICE_DOC_FORM_EXIT,
	INVOICE_DOC_FORM_SAVE = ApiGoalStep.INVOICE_DOC_FORM_SAVE,
	INVOICE_DOC_PREVIEW_EXIT = ApiGoalStep.INVOICE_DOC_PREVIEW_EXIT,
	INVOICE_DOC_PREVIEW_CLOSE = ApiGoalStep.INVOICE_DOC_PREVIEW_CLOSE,
	INVOICE_DOC_PREVIEW_SAVE = ApiGoalStep.INVOICE_DOC_PREVIEW_SAVE,
	INVOICE_DOC_INVALID = ApiGoalStep.INVOICE_DOC_INVALID,
	INVOICE_DOC_CREATED = ApiGoalStep.INVOICE_DOC_CREATED,

	SYNC_START_FROM_LIST = ApiGoalStep.SYNC_START_FROM_LIST,
	SYNC_CHOOSE_UNLOADED = ApiGoalStep.SYNC_CHOOSE_UNLOADED,
	SYNC_CHOOSE_DATE_RANGE = ApiGoalStep.SYNC_CHOOSE_DATE_RANGE,
	SYNC_EXIT = ApiGoalStep.SYNC_EXIT,
	SYNC_IMPORT = ApiGoalStep.SYNC_IMPORT,
	SYNC_CANCEL = ApiGoalStep.SYNC_CANCEL,
	SYNC_IMPORT_ERROR = ApiGoalStep.SYNC_IMPORT_ERROR,
	SYNC_EXIT_ON_ERROR = ApiGoalStep.SYNC_EXIT_ON_ERROR,
	SYNC_ERROR = ApiGoalStep.SYNC_ERROR,
	SYNC_EXIT_ERROR = ApiGoalStep.SYNC_EXIT_ERROR,
	SYNC_RETRY_IMPORT = ApiGoalStep.SYNC_RETRY_IMPORT,
	SYNC_CANCEL_ON_ERROR = ApiGoalStep.SYNC_CANCEL_ON_ERROR,
	SYNC_SUCCESS = ApiGoalStep.SYNC_SUCCESS,
	SYNC_EXIT_SUCCESS = ApiGoalStep.SYNC_EXIT_SUCCESS,
	SYNC_GO_TO_ACCOUNT = ApiGoalStep.SYNC_GO_TO_ACCOUNT,
	SYNC_START_FROM_HEADER = ApiGoalStep.SYNC_START_FROM_HEADER,

	INVOICE_SELECT = ApiGoalStep.INVOICE_SELECT,
	INVOICE_SELECT_ORG_TYPE = ApiGoalStep.INVOICE_SELECT_ORG_TYPE,
	INVOICE_SET_COMPANY = ApiGoalStep.INVOICE_SET_COMPANY,
	INVOICE_SET_CLIENT = ApiGoalStep.INVOICE_SET_CLIENT,
	INVOICE_SET_PRODUCTS = ApiGoalStep.INVOICE_SET_PRODUCTS,
	INVOICE_SET_EMPLOYEE = ApiGoalStep.INVOICE_SET_EMPLOYEE,
	INVOICE_SET_SIGN = ApiGoalStep.INVOICE_SET_SIGN,
	INVOICE_SET_SEAL = ApiGoalStep.INVOICE_SET_SEAL,
	INVOICE_SET_LOGO = ApiGoalStep.INVOICE_SET_LOGO,
	INVOICE_NEXT_TO_PREVIEW = ApiGoalStep.INVOICE_NEXT_TO_PREVIEW,
	INVOICE_SAVE_DRAFT = ApiGoalStep.INVOICE_SAVE_DRAFT,
	INVOICE_BACK_TO_CREATING = ApiGoalStep.INVOICE_BACK_TO_CREATING,
	INVOICE_CREATE = ApiGoalStep.INVOICE_CREATE,
	INVOICE_SET_CLIENT_EMAIL = ApiGoalStep.INVOICE_SET_CLIENT_EMAIL,
	INVOICE_SET_EMAIL = ApiGoalStep.INVOICE_SET_EMAIL,
	INVOICE_SET_MESSAGE = ApiGoalStep.INVOICE_SET_MESSAGE,
	INVOICE_COPY_LINK = ApiGoalStep.INVOICE_COPY_LINK,
	INVOICE_BACK_TO_PREVIEW = ApiGoalStep.INVOICE_BACK_TO_PREVIEW,
	INVOICE_SEND = ApiGoalStep.INVOICE_SEND,
	INVOICE_WHATSAPP = ApiGoalStep.INVOICE_WHATSAPP,
	INVOICE_CREATE_YET = ApiGoalStep.INVOICE_CREATE_YET,
	INVOICE_PDF = ApiGoalStep.INVOICE_PDF,
	INVOICE_FORM_DOC = ApiGoalStep.INVOICE_FORM_DOC,
	INVOICE_WHATSAPP_2 = ApiGoalStep.INVOICE_WHATSAPP_2,
	INVOICE_APP_STORE = ApiGoalStep.INVOICE_APP_STORE,
	INVOICE_GOOGLE_PLAY = ApiGoalStep.INVOICE_GOOGLE_PLAY,
	INVOICE_QR_APP_STORE = ApiGoalStep.INVOICE_QR_APP_STORE,
	INVOICE_QR_GOOGLE_PLAY = ApiGoalStep.INVOICE_QR_GOOGLE_PLAY,
}

function useGoalStep(goalStep: GoalStep) {
	const callback = () => Boolean(goalStep) && goalsApi.achieveStep(goalStep as number);

	return callback;
}

export { useGoalStep };
