import axios from 'axios';

import api from '@api';
import { counterpartyApi } from '@core/api/counterparty';
import { CMSDataType, ServerStatus } from '@funds-registers/models';
import { OperationDateType } from '@pl/model';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import { buildTLongArray, getFundsRegisterType, getPeriodsByYears, setRequestTimeout } from '@utils';
import { createDefaultPeriod } from '@utils/date';
import { getSharedContext } from '@utils/global';
import { detectIsFunction } from '@utils/helpers';
import { fromStringToBoolean } from '@utils/serializer';
import { detectInProgress } from '@utils/server';
import * as fundspackage from 'fundspackage-api';
import * as moment from 'moment';
import { fileApi } from '../file';

const fundsApi = {
	package: fundspackage,
	acquiring: {
		client: new fundspackage.AcquiringServiceClient() as AcquiringServiceClient,
		fetchTerminals: () => {
			return new Promise<Array<PosTerminal>>(resolve => {
				fundsApi.acquiring.client.getTerminals(result => {
					resolve(result);
				});
			});
		},
		importTerminals: () => {
			return new Promise<Array<PosTerminal>>(resolve => {
				fundsApi.acquiring.client.importTerminals(result => {
					resolve(result);
				});
			});
		},
		fetchTransactions: (dateRange: DateRange) => {
			return new Promise<Array<PosTerminalTransaction>>(resolve => {
				const filter: PosTerminalTransactionListRequest = {
					...new fundsApi.package.PosTerminalTransactionListRequest(),
					PeriodStart: dateRange.dateStart,
					PeriodFinish: dateRange.dateEnd,
				};

				fundsApi.acquiring.client.getTransactions(filter, result => {
					resolve(result);
				});
			});
		},
		importTransactions: (options: ImportTransactionsOptions) => {
			const { dateRange, terminal } = options;

			return new Promise<Array<PosTerminalTransaction>>(resolve => {
				const filter: PosTerminalTransactionListRequest = {
					...new fundsApi.package.PosTerminalTransactionListRequest(),
					PeriodStart: dateRange.dateStart,
					PeriodFinish: dateRange.dateEnd,
					Terminal: terminal,
				};

				fundsApi.acquiring.client.importTransactions(filter, result => {
					resolve(result);
				});
			});
		},
		fetchTurnovers: (dateRange: DateRange) => {
			return new Promise<Array<PosTerminalTransactionTurnover>>(resolve => {
				const filter: PosTerminalTransactionListRequest = {
					...new fundsApi.package.PosTerminalTransactionListRequest(),
					PeriodStart: dateRange.dateStart,
					PeriodFinish: dateRange.dateEnd,
				};

				fundsApi.acquiring.client.getTransactionTurnovers(filter, result => {
					resolve(result);
				});
			});
		},
	},
	cashManagementSystem: {
		client: new fundspackage.CashManagementSystemServiceClient(),
		fetchAccounts: (options: CMSAccountListRequest) => {
			return new Promise<CMSAccountsResponse>(resolve => {
				fundsApi.cashManagementSystem.client.getAccounts(options, result => {
					resolve(result);
				});
			});
		},
		fetchCashManagementSystems: () => {
			return new Promise<Array<CashManagementSystem>>(resolve => {
				fundsApi.cashManagementSystem.client.getCashManagementSystems(result => {
					resolve(result.filter(x => x.SubsystemInstanceGUID !== 'VTB_BANK_CMS'));
				});
			});
		},
		importAccountStatementFromCMS: async (options: ImportAccountStatementFromCMSOptions) => {
			const { cmsAccount, cmsGUID, dateRange, dataType, onCheck, onFinish } = options;
			const cms = (await fundsApi.cashManagementSystem.fetchCashManagementSystems()).find(
				x => x.SubsystemInstanceGUID === cmsGUID,
			);

			let transactionDataType = dataType;
			switch (dataType) {
				case CMSDataType.LEGAL_ENTITY_BANK_ACCOUNT:
					transactionDataType = CMSDataType.LEGAL_ENTITY_BANK_TRANSACTION;
					break;
				case CMSDataType.POS_TERMINAL:
					transactionDataType = CMSDataType.POS_TERMINAL_TRANSACTION;
					break;
				case CMSDataType.NATURAL_PERSON_CARD_ACCOUNT:
					transactionDataType = CMSDataType.NATURAL_PERSON_CARD_TRANSACTION;
					break;
			}

			const request = {
				...new fundsApi.package.CMSAccountStatementRequest(),
				CMSAccount: cmsAccount,
				CashManagementSystemID: cms.ID,
				DateStart: dateRange.dateStart,
				DateFinish: dateRange.dateEnd,
				ExternalSystemAccountID: cmsAccount.ExternalSystemAccountID,
				ExternalSystemDataType: transactionDataType,
			};

			return new Promise<AccountStatementImport>(resolve => {
				fundsApi.cashManagementSystem.client.importAccountStatementFromCMS(request, response => {
					if (typeof onCheck === 'function') {
						scheduleCheckAccountStatementImportStatus({
							statementImport: response,
							onCheck,
							onFinish,
						});
					}

					resolve(response);
				});
			});
		},
		importTransactionsFromMarketplace: async (options: ImportTransactionsFromMarketplace) => {
			const { cmsAccount, cmsGUID, dateRange, onCheck, onFinish } = options;
			const cms = (await fundsApi.cashManagementSystem.fetchCashManagementSystems()).find(
				x => x.SubsystemInstanceGUID === cmsGUID,
			);
			const request = {
				...new fundsApi.package.CMSMarketplaceTransactionsRequest(),
				CashManagementSystemID: cms.ID,
				CMSAccount: cmsAccount,
				DateFinish: dateRange.dateEnd,
				DateStart: dateRange.dateStart,
				ExternalSystemAccountID: cmsAccount.ExternalSystemAccountID,
				ExternalSystemDataType: CMSDataType.MARKETPLACE_TRANSACTION,
			};

			return new Promise<AsyncOperationResponse>(resolve => {
				fundsApi.cashManagementSystem.client.importMarketplaceTransactionFromCMS(request, response => {
					if (typeof onCheck === 'function') {
						scheduleCheckImportFromMarketplaceStatus({
							statementImport: response,
							onCheck,
							onFinish,
						});
					}

					resolve(response);
				});
			});
		},
		importCardTransactionFromCMS: async (options: ImportAccountStatementFromCMSOptions) => {
			const { cmsAccount, cmsGUID, dateRange, onCheck } = options;
			const cms = (await fundsApi.cashManagementSystem.fetchCashManagementSystems()).find(
				x => x.SubsystemInstanceGUID === cmsGUID,
			);
			const request = {
				...new fundsApi.package.CMSAccountStatementRequest(),
				CMSAccount: cmsAccount,
				CashManagementSystemID: cms.ID,
				DateStart: dateRange.dateStart,
				DateFinish: dateRange.dateEnd,
				ExternalSystemAccountID: cmsAccount.ExternalSystemAccountID,
				ExternalSystemDataType: CMSDataType.POS_TERMINAL_TRANSACTION,
			};

			return new Promise<CMSCardTransactionsResponse>(resolve => {
				fundsApi.cashManagementSystem.client.importCardTransactionsFromCMS(request, response => {
					onCheck(true, ServerStatus.SUCCESS, '');
					resolve(response);
				});
			});
		},
		importAccountStatementsInBackground: async (options: ImportAccountStatementsInBackgroundOptions) => {
			const { dateRange, cmsGUID, dataType, onCheck } = options;
			const currencyCode = 'RUR';
			const fundsRegisters = (await fundsApi.fundsRegister.fetchFundsRegisters()).filter(
				x => getFundsRegisterType(x) === 'bank' && x?.CashManagementSystem?.SubsystemInstanceGUID === cmsGUID,
			) as Array<BankAccountFundsRegister>;
			const cmsAccounts = fundsRegisters.map(fundsRegister => {
				return {
					...new fundsApi.package.CMSAccount(),
					UID: fundsRegister.SourceUID,
					AccountNumber: fundsRegister.RegisterNumber,
					AccountCurrencyLetterCode: currencyCode,
					BIC: fundsRegister.Bank.Bic,
					ExternalSystemAccountID: fundsRegister.ExternalSystemAccountID,
				};
			});

			return new Promise(resolve => {
				Promise.all(
					cmsAccounts.map(cmsAccount =>
						fundsApi.cashManagementSystem.importAccountStatementFromCMS({
							cmsAccount,
							cmsGUID,
							dateRange,
							dataType: dataType,
						}),
					),
				).then(accountStatementImports => {
					const finishedProceses: Array<AccountStatementImport> = [];

					resolve(true);
					accountStatementImports.forEach((response, _, arr) => {
						scheduleCheckAccountStatementImportStatus({
							statementImport: response,
							detectIsTaskFinished: () => finishedProceses.length === arr.length,
							onFinish: response => finishedProceses.push(response),
							onCheck,
						});
					});
				});
			});
		},
		synchronizePaymentStatus: (options: SynchronizePaymentStatusOptions) => {
			const { paymentID, cashManagementSystemID } = options;
			return new Promise<CMSPaymentStatusResponse>(resolve => {
				const request: CMSPaymentStatusRequest = {
					...new fundsApi.package.CMSPaymentStatusRequest(),
					PaymentID: paymentID,
					CashManagementSystemID: cashManagementSystemID,
				};

				fundsApi.cashManagementSystem.client.synchronizePaymentStatus(request, response => {
					resolve(response);
				});
			});
		},
		importFrom1C: async (options: ImportFrom1COptions) => {
			const { file, onCheck, onFinish, onUploadFile } = options;
			const ticketUID = getSharedContext().ticketUID;
			const data = new FormData();

			data.append('file', file as any);

			return new Promise(resolve => {
				axios
					.post('/atr-framework-services/upload', data, {
						headers: { 'content-type': 'multipart/form-data' },
						params: { ticketUID },
					})
					.then(response => {
						onUploadFile && onUploadFile();
						response.data.forEach(item => {
							const fileReference = new api.fundspackage.AccountStatementFileReference();
							fileReference.UploadedFileName = item.nameOnServer;
							fileReference.UploadDateTime = moment(new Date()).format('DD-MM-YYYY hh:mm:ss');
							fileReference.FileTemplateGUID = '656650cd-8cff-4a64-b3ba-ebf88205978d';
							api.registerServiceClient.importAccountStatementFile(
								fileReference,
								(statementImport: AccountStatementImport) => {
									scheduleCheckAccountStatementFileImportStatus({
										statementImport,
										onCheck,
										onFinish,
									});
								},
							);
						});
						resolve(true);
					});
			});
		},
		importFromExcel: async (options: ImportFromExcelOptions) => {
			const { file, onCheck, onFinish } = options;
			const ticketUID = getSharedContext().ticketUID;
			const data = new FormData();
			data.append('file', file as any);

			return new Promise(resolve => {
				axios
					.post('/atr-framework-services/upload', data, {
						headers: { 'content-type': 'multipart/form-data' },
						params: { ticketUID },
					})
					.then(response => {
						response.data.forEach(item => {
							const fileReference = new api.fundspackage.AccountStatementFileReference();
							fileReference.UploadedFileName = item.nameOnServer;
							fileReference.UploadDateTime = moment(new Date()).format('DD-MM-YYYY hh:mm:ss');
							fileReference.FileTemplateGUID = '6bb518ed-d3b6-43fd-a5a3-569981283687';
							api.paymentsServiceClient.importCashflowOperationsFile(
								fileReference,
								(statementImport: AsyncOperationResponse) => {
									scheduleCheckCashflowOperationsFileImportStatus({
										statementImport,
										onCheck,
										onFinish,
									});
								},
							);
						});
						resolve(true);
					});
			});
		},
		refreshCMSAccounts: () => {
			return new Promise<SuccessMessage>(resolve => {
				fundsApi.cashManagementSystem.client.refreshCMSAccounts(result => {
					resolve(result);
				});
			});
		},
	},
	fundsRegister: {
		client: new fundspackage.FundsRegisterServiceClient(),
		addFundsRegister: (fundsRegister: FundsRegister, initialBalance: number = null, initialDate = '') => {
			return new Promise<FundsRegister>(async resolve => {
				if (typeof fundsRegister.LogoURL === 'object') {
					const url = await fileApi.uploadFile(fundsRegister.LogoURL, 'logo');

					fundsRegister.LogoURL = url;
				}

				fundsApi.fundsRegister.client.createFundsRegister(fundsRegister, initialBalance, initialDate, result => {
					resolve(result);
				});
			});
		},
		addTransferRule: (transferRule: FundsRegisterTransferRule) => {
			return new Promise<FundsRegisterTransferRule>(resolve => {
				fundsApi.fundsRegister.client.addTransferRule(transferRule, result => {
					resolve(result);
				});
			});
		},
		applyTransferRules: (dateRange: DateRange) => {
			const dateFrom = dateRange.dateStart;
			const dateTo = dateRange.dateEnd;
			return new Promise<boolean>(resolve => {
				fundsApi.fundsRegister.client.applyTransferRules(dateFrom, dateTo, result => {
					resolve(result);
				});
			});
		},
		assignLegalEntityToFundsRegisters: (options: AssignLegalEntityToFundsRegistersOptions) => {
			return new Promise<boolean>(async resolve => {
				const { legalEntityID, addIDs = [], removeIDs = [], onComplete } = options;

				const result = await Promise.all([
					...addIDs.map(async ID => {
						const fundsRegister = await fundsApi.fundsRegister.fetchFundsRegisterByID(ID);
						fundsRegister.LegalEntity = { ...new counterpartyApi.package.CounterpartyBrief(), ID: legalEntityID };

						return await fundsApi.fundsRegister.updateFundsRegister(fundsRegister);
					}),
					...removeIDs.map(async ID => {
						const fundsRegister = await fundsApi.fundsRegister.fetchFundsRegisterByID(ID);
						fundsRegister.LegalEntity = null;

						return await fundsApi.fundsRegister.updateFundsRegister(fundsRegister);
					}),
				]);

				resolve(result.every(Boolean));
				detectIsFunction(onComplete) && onComplete();
			});
		},
		createOpenBankAccountRequest: (openRequest: FundsRegisterOpenRequest) => {
			return new Promise<FundsRegisterOpenResponse>(resolve => {
				fundsApi.fundsRegister.client.createOpenBankAccountRequest(openRequest, result => {
					resolve(result);
				});
			});
		},
		createFundsRegisterFromCmsAccount(account: CMSAccount) {
			return new Promise<BankAccountFundsRegister>(resolve => {
				fundsApi.fundsRegister.client.createFundsRegisterFromCMSAccount(account, result => {
					resolve(result);
				});
			});
		},
		fetchCashflowForecast: (options: FetchCashflowForecastOptions) => {
			const {
				accountsChartItemIDs = [],
				businessUnitIDs = [],
				dateRange,
				dateType = OperationDateType.CASHFLOW,
				excludeArchiveRegister = false,
				fundsRegisterIDs = [],
				inConsolidation = false,
				projectIDs = [],
				tenantLegalEntityIDs = [],
			} = options;

			return new Promise<CashflowForecast>(resolve => {
				const request: CashflowForecastRequest = {
					...new fundsApi.package.CashflowForecastRequest(),
					AccountsChartItemIDList: buildTLongArray(accountsChartItemIDs),
					AccrualDateAggregation: dateType === OperationDateType.ACCRUAL,
					AnalysisDate: moment().format(BASE_DATE_FORMAT),
					BusinessUnitIDList: buildTLongArray(businessUnitIDs),
					ExcludeArchiveRegister: excludeArchiveRegister,
					FinishDate: dateRange.dateEnd,
					FundsRegisterIDList: buildTLongArray(fundsRegisterIDs),
					InConsolidation: inConsolidation,
					ProjectIDList: buildTLongArray(projectIDs),
					StartDate: dateRange.dateStart,
					TenantLegalEntityIDList: buildTLongArray(tenantLegalEntityIDs),
				};

				fundsApi.fundsRegister.client.getCashflowForecast(request, result => {
					resolve(result);
				});
			});
		},
		fetchFundsRegisterAccesses(fundsRegisterID: number) {
			return new Promise<Array<FundsRegisterEmployeeAccess>>(resolve => {
				fundsApi.fundsRegister.client.getFundsRegisterAccessList(fundsRegisterID, result => {
					resolve(result);
				});
			});
		},
		fetchFundsRegisterBalance: (options: FetchFundsRegisterBalanceOptions) => {
			const {
				fundsRegisterID,
				dateRange: { dateStart, dateEnd },
			} = options;
			return new Promise<Array<CashBalanceRecord>>(resolve => {
				let cashBalanceRecords = [];
				const periods = getPeriodsByYears(5, dateStart, dateEnd);
				const delay = 200;
				const timeouts = [];

				let timeout = setTimeout(function getBalance() {
					const date = periods[0];

					fundsApi.fundsRegister.client.getCashBalancesForPeriod(
						fundsRegisterID,
						date.start,
						date.end,
						(result: Array<CashBalanceRecord>) => {
							periods.shift();
							const isCovered = periods.length === 0;

							cashBalanceRecords = cashBalanceRecords.concat(result);
							if (isCovered) {
								resolve(cashBalanceRecords);
								timeouts.forEach(timeout => clearTimeout(timeout));
							} else {
								timeout = setTimeout(getBalance, delay);
								timeouts.push(timeout);
							}
						},
					);
				}, delay);
				timeouts.push(timeout);
			});
		},
		fetchFundsRegisterBoundaryBalances: (fundsRegisterID: number) => {
			return new Promise<Array<CashBalanceRecord>>(resolve => {
				fundsApi.fundsRegister.client.getFundsRegisterBoundaryBalances(fundsRegisterID, result => {
					resolve(result);
				});
			});
		},
		fetchFundsRegisterByID: (ID: number) => {
			return new Promise<FundsRegister>(resolve => {
				fundsApi.fundsRegister.client.getFundsRegisterByID(ID, result => {
					resolve(result);
				});
			});
		},
		fetchFundsRegisterPurposes: () => {
			return new Promise<Array<FundsRegisterPurpose>>(resolve => {
				fundsApi.fundsRegister.client.getFundsRegisterPurposes(result => {
					resolve(result);
				});
			});
		},
		fetchFundsRegisters: () => {
			return new Promise<Array<FundsRegister>>(resolve => {
				const dateRange = createDefaultPeriod('quarter');

				fundsApi.fundsRegister.client.getFundsRegisterStatistics(dateRange.dateStart, dateRange.dateEnd, result => {
					const fundsRegisters = result.map(frs => frs.FundsRegister);

					resolve(fundsRegisters);
				});
			});
		},
		fetchFundsRegisterStatistics: (dateRange: DateRange = createDefaultPeriod('quarter')) => {
			return new Promise<Array<FundsRegisterStatistics>>(resolve => {
				fundsApi.fundsRegister.client.getFundsRegisterStatistics(dateRange.dateStart, dateRange.dateEnd, result => {
					resolve(result);
				});
			});
		},
		fetchLastAccountStatementImports: () => {
			return new Promise<Array<AccountStatementImport>>(resolve => {
				fundsApi.fundsRegister.client.getLastAccountStatementImportList(result => {
					resolve(result);
				});
			});
		},
		fetchOpenBankAccountRequests: () => {
			return new Promise<Array<FundsRegisterOpenRequest>>(resolve => {
				fundsApi.fundsRegister.client.getOpenBankAccountRequestList(result => {
					resolve(result);
				});
			});
		},
		fetchSummaryCashBalance: (options: FetchSummaryCashBalanceOptions) => {
			const { dateRange, leIDs = [], inConsolidation = false } = options;

			return new Promise<Array<CashBalanceRecord>>(resolve => {
				const request: SummaryCashBalanceForPeriodRequest = {
					...new fundsApi.package.SummaryCashBalanceForPeriodRequest(),
					DateFrom: dateRange.dateStart,
					DateTo: dateRange.dateEnd,
					OrganizationIDs: buildTLongArray(leIDs),
					InConsolidation: inConsolidation,
				};

				fundsApi.fundsRegister.client.getSummaryCashBalanceForPeriod(request, result => {
					resolve(result);
				});
			});
		},
		fetchTransferRuleByID: (ID: number) => {
			return new Promise<FundsRegisterTransferRule>(resolve => {
				fundsApi.fundsRegister.client.getTransferRuleByID(ID, result => {
					resolve(result);
				});
			});
		},
		fetchTransferRules: () => {
			return new Promise<Array<FundsRegisterTransferRule>>(resolve => {
				fundsApi.fundsRegister.client.getTransferRuleList(result => {
					resolve(result);
				});
			});
		},
		recalculateFundsRegisterBalances: (options: RecalculateFundsRegisterBalancesOptions) => {
			const { fundRegisterID, startBalance } = options;

			return new Promise<AsyncOperationResponse>(resolve => {
				fundsApi.fundsRegister.client.recalculateFundsRegisterBalances(fundRegisterID, startBalance, response => {
					const isProgress = detectInProgress(response.Status);

					if (isProgress) {
						setRequestTimeout(
							fundsApi.fundsRegister.client,
							fundsApi.fundsRegister.client.getBalanceRecalculationStatus,
							response.OperationUID,
							(response: AsyncOperationResponse) => {
								const isProgress = detectInProgress(response.Status);
								const isFinished = !isProgress;

								isFinished && resolve(response);

								return isFinished;
							},
							5000,
						);
					} else {
						resolve(response);
					}
				});
			});
		},
		removeFundsRegister: (fundsRegisterID: number) => {
			return new Promise<boolean>(resolve => {
				fundsApi.fundsRegister.client.removeFundsRegister(fundsRegisterID, result => {
					resolve(result);
				});
			});
		},
		removeTransferRule: (ID: number) => {
			return new Promise<boolean>(resolve => {
				fundsApi.fundsRegister.client.removeTransferRule(ID, result => {
					resolve(result);
				});
			});
		},
		updateFundsRegister: (fundsRegister: FundsRegister) => {
			return new Promise<FundsRegister>(async resolve => {
				if (typeof fundsRegister.LogoURL === 'object') {
					const url = await fileApi.uploadFile(fundsRegister.LogoURL, 'logo');

					fundsRegister.LogoURL = url;
				}

				fundsApi.fundsRegister.client.changeFundsRegister(fundsRegister, result => {
					resolve(result);
				});
			});
		},
		updateFundsRegisterAccesses(accesses: Array<FundsRegisterEmployeeAccess>) {
			return new Promise<boolean>(resolve => {
				fundsApi.fundsRegister.client.setFundsRegistersAccess(accesses, result => {
					resolve(fromStringToBoolean(result));
				});
			});
		},
		updateTransferRule: (transferRule: FundsRegisterTransferRule) => {
			return new Promise<FundsRegisterTransferRule>(resolve => {
				fundsApi.fundsRegister.client.updateTransferRule(transferRule, result => {
					resolve(result);
				});
			});
		},
	},
};

type SynchronizePaymentStatusOptions = {
	paymentID: number;
	cashManagementSystemID: number;
};

type CheckAccountStatementImportStatusOptions = {
	statementImport: AccountStatementImport;
	detectIsTaskFinished?: () => boolean;
	timeout?: number;
	onFinish?: (statementImport: AccountStatementImport) => void;
	onCheck: (isFinished: boolean, status: string, message?: string) => void;
};

function scheduleCheckAccountStatementImportStatus(options: CheckAccountStatementImportStatusOptions) {
	const { statementImport, timeout = 5 * 1000, detectIsTaskFinished, onFinish = () => {}, onCheck } = options;
	const status = statementImport.ImportStatusCode;
	const message = statementImport.Message || '';
	const isProgress = detectInProgress(status);
	const isFinished = !isProgress;

	if (isFinished) {
		onFinish(statementImport);
	}

	if (isProgress) {
		setRequestTimeout(
			fundsApi.fundsRegister.client,
			fundsApi.fundsRegister.client.getAccountStatementImport,
			statementImport.AccountStatementID,
			(response: AccountStatementImport) => {
				const status = response.ImportStatusCode;
				const message = response.Message || '';
				const isProgress = detectInProgress(status);
				const isFinished = !isProgress;

				if (isFinished) {
					onFinish(response);
				}

				onCheck(checkFinish(isFinished), status, message);

				return isFinished;
			},
			timeout,
		);
	} else {
		onCheck(checkFinish(isFinished), status, message);
	}

	function checkFinish(isFinished: boolean) {
		return typeof detectIsTaskFinished === 'function' ? detectIsTaskFinished() : isFinished;
	}
}

type CheckImportFromMarketplaceStatusOptions = {
	detectIsTaskFinished?: () => boolean;
	onCheck: (isFinished: boolean, status: string, message?: string) => void;
	onFinish?: (statementImport: AsyncOperationResponse) => void;
	statementImport: AsyncOperationResponse;
	timeout?: number;
};

function scheduleCheckImportFromMarketplaceStatus(options: CheckImportFromMarketplaceStatusOptions) {
	const { statementImport, timeout = 5 * 1000, detectIsTaskFinished, onFinish = () => {}, onCheck } = options;
	const status = statementImport.Status;
	const message = statementImport.Message || '';
	const isProgress = detectInProgress(status);
	const isFinished = !isProgress;

	if (isFinished) {
		onFinish(statementImport);
	}

	if (isProgress) {
		setRequestTimeout(
			fundsApi.cashManagementSystem.client,
			fundsApi.cashManagementSystem.client.getImportMarketplaceTransactionStatus,
			statementImport.OperationUID,
			(response: AsyncOperationResponse) => {
				const status = response.Status;
				const message = response.Message || '';
				const isProgress = detectInProgress(status);
				const isFinished = !isProgress;

				if (isFinished) {
					onFinish(response);
				}

				onCheck(checkFinish(isFinished), status, message);

				return isFinished;
			},
			timeout,
		);
	} else {
		onCheck(checkFinish(isFinished), status, message);
	}

	function checkFinish(isFinished: boolean) {
		return typeof detectIsTaskFinished === 'function' ? detectIsTaskFinished() : isFinished;
	}
}

type CheckAccountStatementFileImportStatusOptions = {
	statementImport: AccountStatementImport;
	timeout?: number;
	onFinish: (statementImport: AccountStatementImport) => void;
	onCheck: (status: ServerStatus, message?: string) => void;
};

function scheduleCheckAccountStatementFileImportStatus(options: CheckAccountStatementFileImportStatusOptions) {
	const { statementImport, timeout = 5 * 1000, onFinish, onCheck } = options;
	const status = statementImport.ImportStatusCode as ServerStatus;
	const message = statementImport.Message || '';
	const isProgress = detectInProgress(status);
	const isFinished = !isProgress;

	if (isFinished) {
		onFinish(statementImport);
	}

	if (isProgress) {
		setRequestTimeout(
			fundsApi.fundsRegister.client,
			fundsApi.fundsRegister.client.getAccountStatementImport,
			statementImport.AccountStatementID,
			(response: AccountStatementImport) => {
				const status = response.ImportStatusCode as ServerStatus;
				const message = response.Message || '';
				const isProgress = detectInProgress(status);
				const isFinished = !isProgress;

				if (isFinished) {
					onFinish(response);
				}

				onCheck(status, message);

				return isFinished;
			},
			timeout,
		);
	} else {
		onCheck(status, message);
	}
}

type CheckCashflowOperationsFileImportStatusOptions = {
	statementImport: AsyncOperationResponse;
	timeout?: number;
	onFinish: () => void;
	onCheck: (status: ServerStatus, message?: string) => void;
};

function scheduleCheckCashflowOperationsFileImportStatus(options: CheckCashflowOperationsFileImportStatusOptions) {
	const { statementImport, timeout = 5 * 1000, onFinish, onCheck } = options;
	const status = statementImport.Status as ServerStatus;
	const message = statementImport.Message || '';
	const isProgress = detectInProgress(status);
	const isFinished = !isProgress;

	if (isFinished) {
		onFinish();
	}

	if (isProgress) {
		setRequestTimeout(
			api.paymentsServiceClient,
			api.paymentsServiceClient.getCashflowOperationsImportStatus,
			statementImport.OperationUID,
			(response: AsyncOperationResponse) => {
				const status = response.Status as ServerStatus;
				const message = response.Message || '';
				const isProgress = detectInProgress(status);
				const isFinished = !isProgress;

				if (isFinished) {
					onFinish();
				}

				onCheck(status, message);

				return isFinished;
			},
			timeout,
		);
	} else {
		onCheck(status, message);
	}
}

export type RecalculateFundsRegisterBalancesOptions = {
	fundRegisterID: number;
	startBalance: number;
};

export type FetchSummaryCashBalanceOptions = {
	dateRange: DateRange;
	leIDs?: Array<number>;
	inConsolidation?: boolean;
};

export type FetchCashflowForecastOptions = {
	accountsChartItemIDs?: Array<number>;
	businessUnitIDs?: Array<number>;
	dateRange: DateRange;
	dateType?: OperationDateType;
	excludeArchiveRegister?: boolean;
	fundsRegisterIDs?: Array<number>;
	inConsolidation?: boolean;
	projectIDs?: Array<number>;
	tenantLegalEntityIDs?: Array<number>;
};

export type ImportAccountStatementFromCMSOptions = {
	cmsAccount: CMSAccount;
	cmsGUID: string;
	dateRange: DateRange;
	dataType: string;
	onCheck?: (isFinished: boolean, status: ServerStatus, message?: string) => void;
	onFinish?: (statementImport: AccountStatementImport) => void;
};

export type ImportTransactionsFromMarketplace = {
	cmsAccount: CMSAccount;
	cmsGUID: string;
	dataType: string;
	dateRange: DateRange;
	onCheck?: (isFinished: boolean, status: ServerStatus, message?: string) => void;
	onFinish?: (statementImport: AsyncOperationResponse) => void;
};

export type ImportFrom1COptions = {
	file: IUploadFile;
	onCheck: (status: ServerStatus, message?: string) => void;
	onFinish: (statementImport: AccountStatementImport) => void;
	onUploadFile?: () => void;
};

export type ImportFromExcelOptions = {
	file: IUploadFile;
	onCheck: (status: ServerStatus, message?: string) => void;
	onFinish: () => void;
};

export type ImportAccountStatementsInBackgroundOptions = {
	cmsGUID: string;
	dateRange: DateRange;
	dataType: string;
	onCheck: (isFinished: boolean, status: ServerStatus, message?: string) => void;
};

export type AddFundsRegisterOptions = {
	fundsRegister: FundsRegister;
	initialBalance?: number;
	initialDate?: string;
};

export type FetchFundsRegisterBalanceOptions = {
	fundsRegisterID: number;
	dateRange: DateRange;
};

export type AssignLegalEntityToFundsRegistersOptions = {
	legalEntityID: number;
	addIDs?: Array<number>;
	removeIDs?: Array<number>;
	onComplete?: () => void;
};

export type ImportTransactionsOptions = {
	dateRange: DateRange;
	terminal: PosTerminal;
};

export enum PaymentSystem {
	VISA = 'Visa',
	MC = 'MC',
	AMEX = 'AMEX',
	MIR = 'MIR',
	GOLDEN_CROWN = 'GOLDEN_CROWN',
}

export enum PaymentSystemName {
	VISA = 'Visa',
	MC = 'MasterCard',
	AMEX = 'American Express',
	MIR = 'НСПК, МИР',
	GOLDEN_CROWN = 'Золотая Корона',
}

export enum CardType {
	DEBIT = 'DEBIT',
	CREDIT = 'CREDIT',
}

export enum CardTypeName {
	DEBIT = 'Дебетовая',
	CREDIT = 'Кредитная',
}

export enum OperationType {
	INCOME = 'INCOME',
	EXPENSE = 'EXPENSE',
}

export enum OperationFailReasonCode {
	UNKNOWN_ERROR = 'UNKNOWN_ERROR',
	CRYPTO_ERROR = 'CRYPTO_ERROR',
	INCORRECT_PIN = 'INCORRECT_PIN',
	NO_SUCH_CARD = 'NO_SUCH_CARD',
	INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
	RESTRICTED_CARD = 'RESTRICTED_CARD',
	DO_NOT_HONOUR = 'DO_NOT_HONOUR',
	REPEAT = 'REPEAT',
}

export enum OperationFailReasonName {
	UNKNOWN_ERROR = 'Неизвестная ошибка',
	CRYPTO_ERROR = 'Криптографическая ошибка',
	INCORRECT_PIN = 'Некорректный PIN-код',
	NO_SUCH_CARD = 'Не найдена карта',
	INSUFFICIENT_FUNDS = 'Недостаточно средств',
	RESTRICTED_CARD = 'Запрещённая карта',
	DO_NOT_HONOUR = 'Отказ в приеме транзакции (Do not honour)',
	REPEAT = 'Повтор',
	EXPIRED_ON_CONFIRMATION = 'Истек срок подтверждения',
}

export { fundsApi };
