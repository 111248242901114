import React, { memo, useEffect } from 'react';

import { AsyncProgressStatus } from '@core/api/core';
import { useMapDispatch, useMapState } from '@core/flux';
import { GoalStep, useGoalStep } from '@core/hooks/use-goals';
import { DrawerZone } from '@funds-registers/models';
import { mainFundsRegistersSelectorsPack } from '@funds-registers/selectors';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import {
	selectLastAccountStatementImport,
	selectLastImportedAccountData,
	selectLastImportedFileData,
} from '@integrations/selectors';
import { mainPaymentsActionsPack } from '@payments/actions';
import { getDrawer } from '@utils';
import { XImportResult, XImportResultProps } from './import-result.view';

type ImportResultProps = {
	setZone: React.Dispatch<React.SetStateAction<DrawerZone>>;
} & Pick<XImportResultProps, 'zone' | 'status'>;

const ImportResult: React.FC<ImportResultProps> = memo(props => {
	const { zone, status, setZone } = props;
	const [fundsRegisterStatisticsMap, lastImportedAccountData, lastImportedFileData, lastAccountStatementImport] =
		useMapState([
			mainFundsRegistersSelectorsPack.selectFundsRegisterStatisticsMap,
			selectLastImportedAccountData,
			selectLastImportedFileData,
			selectLastAccountStatementImport,
		]);
	const [clearLastImportedAccountData, clearLastImportedFileData, setFundsRegisterID] = useMapDispatch([
		mainIntegrationsActionsPack.clearLastImportedAccountData,
		mainIntegrationsActionsPack.clearLastImportedFileData,
		mainPaymentsActionsPack.setFundsRegisterID,
	]);
	const handleGoalStepOnSuccessRefresh = useGoalStep(GoalStep.SYNC_SUCCESS);
	const handleGoalStepOnFailedRefresh = useGoalStep(GoalStep.SYNC_ERROR);

	useEffect(() => {
		if (zone === DrawerZone.REFRESH_CMS_RESULT) {
			const isSuccess = status.status === 'SUCCESS';
			const isFailed = status.status === 'FAILED';

			isSuccess && handleGoalStepOnSuccessRefresh();
			isFailed && handleGoalStepOnFailedRefresh();
		}
	}, [status, zone]);

	const handleClose = () => {
		clearLastImportedAccountData();
		clearLastImportedFileData();
		getDrawer().close();
	};

	const handleAddAccount = () => {
		setZone(DrawerZone.ACOUNTS);
		clearLastImportedAccountData();
	};

	const handleAddImportFile = () => {
		setZone(DrawerZone.IMPORT_FILE);
		clearLastImportedFileData();
	};

	const handleRepeat = () => {
		switch (zone) {
			case DrawerZone.IMPORT_CMS_RESULT:
				setZone(DrawerZone.IMPORT_CMS);
				clearLastImportedAccountData();
				break;
			case DrawerZone.REFRESH_CMS_RESULT:
				setZone(DrawerZone.REFRESH_CMS);
				clearLastImportedAccountData();
				break;
			case DrawerZone.IMPORT_FILE_RESULT:
				handleAddImportFile();
				clearLastImportedFileData();
				break;
		}
	};

	return (
		<XImportResult
			zone={zone}
			status={status}
			fundsRegisterStatisticsMap={fundsRegisterStatisticsMap}
			lastImportedAccountData={lastImportedAccountData}
			lastImportedFileData={lastImportedFileData}
			lastAccountStatementImport={lastAccountStatementImport}
			setFundsRegisterID={setFundsRegisterID}
			onClose={handleClose}
			onAddAccount={handleAddAccount}
			onAddImportFile={handleAddImportFile}
			onRepeat={handleRepeat}
		/>
	);
});

export { ImportResult };
