import axios from 'axios';

import { getAuthParams } from '@config';
import { getUserData } from '@utils/app';
import { detectIsLocalhost } from '@utils/env';

const API_ENDPOINT = '/snc-goals-services/api/v1/goals';
const goalsApi = {
	achieveGoal: (options: AchieveGoalOptions) => {
		if (isLocalhost) return;
		return new Promise<void>(async (resolve, reject) => {
			const {
				goalID,
				tenantID,
				userID,
				clientApplicationID,
				registrationMethodID = 1,
				securitySessionID = -1,
			} = options;

			try {
				const formData = new FormData();

				formData.append(
					'goalAchievement',
					JSON.stringify({
						goalID,
						tenantID,
						userID,
						clientApplicationID,
						registrationMethodID,
						securitySessionID,
					}),
				);
				await axios({
					method: 'post',
					url: `${API_ENDPOINT}/goal`,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					data: formData,
				});
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	},
	achieveStep: (goalStepID: GoalStep) => {
		if (isLocalhost) return;
		return new Promise<void>(async resolve => {
			try {
				const { tenantID, userID } = getUserData();
				const { clientApplication } = getAuthParams();
				const clientApplicationID = clientApplicationIDsMap[clientApplication] || -1;
				const registrationMethodID = 1;
				const securitySessionID = -1;
				const formData = new FormData();

				formData.append(
					'goalStepAchievement',
					JSON.stringify({
						goalStepID,
						tenantID,
						userID,
						clientApplicationID,
						registrationMethodID,
						securitySessionID,
					}),
				);

				await axios({
					method: 'post',
					url: `${API_ENDPOINT}/goal-step`,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					data: formData,
				});
				resolve();
			} catch (error) {
				console.log(error);
			}
		});
	},
};

type AchieveGoalOptions = {
	goalID: number;
	tenantID: number;
	userID: number;
	clientApplicationID: number;
	registrationMethodID?: number;
	securitySessionID?: number;
};

export enum GoalStep {
	CASHFLOW_REPORT_ENTER = 3,
	CASHFLOW_REPORT_FILTER = 4,
	CASHFLOW_REPORT_LEAVE = 5,
	ACCRUAL_REPORT_ENTER = 6,
	ACCRUAL_REPORT_FILTER = 7,
	ACCRUAL_REPORT_LEAVE = 8,
	PROFIT_LOSS_REPORT_ENTER = 9,
	PROFIT_LOSS_REPORT_FILTER = 10,
	PROFIT_LOSS_REPORT_LEAVE = 11,
	BUSINESS_UNIT_EDIT_INIT = 12,
	BUSINESS_UNIT_EDIT_CONFIRM = 13,

	IMPORT_1C_SELECT = 18,
	IMPORT_1C_DROP = 19,
	IMPORT_1C_UPLOAD = 20,
	IMPORT_1C_TEMPLATE = 21,
	IMPORT_1C_START = 22,
	IMPORT_1C_EXIT = 23,
	IMPORT_1C_CANCEL = 24,
	IMPORT_1C_FAIL = 25,
	IMPORT_1C_SUCCESS = 26,

	INVOICE_DOC_LIST_ADD = 27,
	INVOICE_DOC_CARD_ADD = 28,
	INVOICE_DOC_FORM_PREVIEW = 29,
	INVOICE_DOC_FORM_EXIT = 30,
	INVOICE_DOC_FORM_SAVE = 31,
	INVOICE_DOC_PREVIEW_EXIT = 32,
	INVOICE_DOC_PREVIEW_CLOSE = 33,
	INVOICE_DOC_PREVIEW_SAVE = 34,
	INVOICE_DOC_INVALID = 35,
	INVOICE_DOC_CREATED = 36,

	SYNC_START_FROM_LIST = 37,
	SYNC_CHOOSE_UNLOADED = 38,
	SYNC_CHOOSE_DATE_RANGE = 39,
	SYNC_EXIT = 40,
	SYNC_IMPORT = 41,
	SYNC_CANCEL = 42,
	SYNC_IMPORT_ERROR = 43,
	SYNC_EXIT_ON_ERROR = 44,
	SYNC_ERROR = 45,
	SYNC_EXIT_ERROR = 46,
	SYNC_RETRY_IMPORT = 47,
	SYNC_CANCEL_ON_ERROR = 48,
	SYNC_SUCCESS = 49,
	SYNC_EXIT_SUCCESS = 50,
	SYNC_GO_TO_ACCOUNT = 51,
	SYNC_START_FROM_HEADER = 52,

	INVOICE_SELECT = 53,
	INVOICE_SELECT_ORG_TYPE = 54,
	INVOICE_SET_COMPANY = 55,
	INVOICE_SET_CLIENT = 56,
	INVOICE_SET_PRODUCTS = 57,
	INVOICE_SET_EMPLOYEE = 58,
	INVOICE_SET_SIGN = 59,
	INVOICE_SET_SEAL = 60,
	INVOICE_SET_LOGO = 61,
	INVOICE_NEXT_TO_PREVIEW = 62,
	INVOICE_SAVE_DRAFT = 63,
	INVOICE_BACK_TO_CREATING = 64,
	INVOICE_CREATE = 65,
	INVOICE_SET_CLIENT_EMAIL = 66,
	INVOICE_SET_EMAIL = 67,
	INVOICE_SET_MESSAGE = 68,
	INVOICE_COPY_LINK = 69,
	INVOICE_BACK_TO_PREVIEW = 70,
	INVOICE_SEND = 71,
	INVOICE_WHATSAPP = 72,
	INVOICE_CREATE_YET = 73,
	INVOICE_PDF = 74,
	INVOICE_FORM_DOC = 75,
	INVOICE_WHATSAPP_2 = 76,
	INVOICE_APP_STORE = 77,
	INVOICE_GOOGLE_PLAY = 78,
	INVOICE_QR_APP_STORE = 79,
	INVOICE_QR_GOOGLE_PLAY = 80,
}

const clientApplicationIDsMap = {
	web_bfm: 1,
	web_invoice: 2,
	web_ba: 3,
	web_invoice_sberbank: 13,
};

const isLocalhost = detectIsLocalhost();

export { goalsApi };
